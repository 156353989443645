import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Fred = () => {
  return (
    <Layout>
      <Head title="Fred again..." />
      <h3>Fred again...</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        London, UK
      </h4>
      <p>
        <OutboundLink href="https://www.fredagain.com/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/fredagainagainagainagainagain/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        British singer, songwriter, multi-instrumentalist, record producer and
        remixer.
      </p>
      <p>
        Fred is known for the range of artists he has worked with including
        Skrillex, FKA Twigs, Swedish House Mafia, BTS, Baxter Dury, Stormzy, Ed
        Sheeran, Burna Boy, Headie One, Halsey, Eminem, Octavian, The xx,
        Underworld, Perrie Edwards, Brian Eno and Stefflon Don.
      </p>
      <p>At the age of 16, Gibson started being mentored by Brian Eno.</p>
      <p>
        Released in 2020 and 2021 over the course of the Coronavirus pandemic,
        Fred Again's first two albums were positively received by publications
        like NME, OnesToWatch, Guardian, Loud and Quiet, and Clash.
      </p>
      <p>
        The albums' tracks are often built around voice notes from friends, and
        deal with the effects of the pandemic, the loss of friends, heartbreak
        and loneliness.
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/c0-hvjV2A5Y"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/bZH9U1efx1c"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/XpBRuwK5aN4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/H2I6V0NlaHg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Fred;
